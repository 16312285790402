import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from 'react';
import "./header.sass"

import Logo from "../images/logo.svg"
import menu from "../images/icons/menu.svg"

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {showMobileMenuItems: false};
    this.handleClick = this.handleClick.bind(this);
  }

   handleClick() {
    this.setState(prevState => ({
      showMobileMenuItems: !prevState.showMobileMenuItems
    }));
  }

  render() {

    const mobileHeader = () => (
      <div className="header__menu header__menu--mobile">
        <img className="header__hamburger--mobile" src={menu} alt="menu" onClick={this.handleClick}/>
        <div className="header__links header__links--mobile" style={{display: this.state.showMobileMenuItems ? 'block' : 'none' }}>
          <div className="header__link-container header__link-container--mobile">
            <Link to="/" activeClassName="header__link--active--mobile">Home</Link>
          </div>
          <div className="header__link-container header__link-container--mobile">
            <Link to="/classroom" activeClassName="header__link--active--mobile">The Program</Link>
          </div>
          <div className="header__link-container header__link-container--mobile">
            <Link to="/team" activeClassName="header__link--active--mobile">Our Team</Link>
          </div>
          <div className="header__link-container header__link-container--mobile">
            <Link to="/get-involved" activeClassName="header__link--active--mobile">Get Involved</Link>
          </div>
        </div>
      </div>
    )

    const normalHeader = () => (
      <div className="header__menu header__menu--desktop">
        <div className="header__links">
          <div className="header__link-container">
            <Link to="/" activeClassName="header__link--active">Home</Link>
          </div>
          <div className="header__link-container">
            <Link to="/classroom" activeClassName="header__link--active">The Program</Link>
          </div>
          <div className="header__link-container">
            <Link to="/team" activeClassName="header__link--active">Our Team</Link>
          </div>
          <div className="header__link-container">
            <Link to="/get-involved" activeClassName="header__link--active">Get Involved</Link>
          </div>
        </div>
      </div>
    )

    return (
      <header className="header">
        <div className="header__logo"><Link to="/"><img src={Logo}/></Link></div>
        {normalHeader()}
        {mobileHeader()}
        <div className="header__npo">210-208 NPO</div>
      </header>
    );
  }
}

export default Header


// <div className="header__link-container">
//   <Link to="/">Donate</Link>
// </div>
// <div className="header__link-container">
//   <Link to="/">Blog</Link>
// </div>
